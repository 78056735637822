import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EuroIcon from '@material-ui/icons/Euro';
import HttpIcon from '@material-ui/icons/Http';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AtmIcon from '@material-ui/icons/Atm';
import RefreshIcon from '@material-ui/icons/Refresh';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import StyleIcon from '@material-ui/icons/Style';
import DialogPayment from './presentation.dialog.payment';
import DialogTrend from './presentation.dialog.trend';

const useStyles = makeStyles({
  filtersContainer: {
    padding: '1rem',
  },
  button: {
    margin: '0.5rem',
  },
  listItemIcon: {
    minWidth: '2rem',
  },
});

const DateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
const getDate = (timestamp, locale) => {
  let date = new Date(timestamp);
  return date.toLocaleTimeString(locale.replace('_', '-'), DateOptions);
};

const getConsume = (start, stop) => {
  start = parseFloat(start);
  stop = parseFloat(stop);
  let diff = Math.round(stop - start) / 1000;
  return `${diff} kWh`;
};

const getType = (type, payment) => {
  switch (type) {
    case 'local':
      return (
        <Tooltip title={<Translate id='transactions.type.local' />}>
          <StyleIcon color='action' />
        </Tooltip>
      );

    case 'remote':
      if (payment && payment.method && payment.method === 'nayax') {
        return (
          <Tooltip title={<Translate id='transactions.paymentMethods.nayax' />}>
            <AtmIcon color='action' />
          </Tooltip>
        );
      }
      return (
        <Tooltip title={<Translate id='transactions.type.remote' />}>
          <PhoneAndroidIcon color='action' />
        </Tooltip>
      );

    case 'api':
      return (
        <Tooltip title={<Translate id='transactions.type.api' />}>
          <HttpIcon color='action' />
        </Tooltip>
      );

    default:
      return null;
  }
};

const getStatus = (unlocked) => {
  if (unlocked) {
    return (
      <Tooltip title={<Translate id='transactions.status.unlocked' />}>
        <ThumbDownIcon color='action' />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={<Translate id='transactions.status.ok' />}>
        <ThumbUpIcon color='action' />
      </Tooltip>
    );
  }
};

const getOptionLabel = (chargeboxes) => (option) => {
  if (typeof option === 'string') {
    let chargebox = chargeboxes.find((c) => c.id === option);
    if (chargebox === undefined) {
      return 'UNKNOWN';
    }
    return chargebox.alias;
  }
  return option.alias;
};

const getOptionClientLabel = (clients) => (option) => {
  if (typeof option === 'string') {
    let client = clients.find((c) => c.id === option);
    if (client === undefined) {
      return 'UNKNOWN';
    }
    return client.name;
  }
  return option.name;
};

const FinishedTransactions = (props) => {
  const classes = useStyles(props);
  return (
    <Paper elevation={3}>
      <DialogPayment
        open={props.dialogPaymentOpen}
        handleClose={props.toggleDialogPayment}
        type={props.selectedTransaction === null ? null : props.selectedTransaction.type}
        user={props.selectedTransaction === null ? null : props.users[props.selectedTransaction.user]}
        payment={props.selectedTransaction === null ? null : props.selectedTransaction.payment}
        price={
          props.selectedTransaction === null
            ? null
            : props.selectedTransaction['payment.price']
            ? props.selectedTransaction['payment.price']
            : props.selectedTransaction['payment.amount']
        }
        reservation={props.selectedTransaction === null || !props.selectedTransaction.reservation ? null : props.selectedTransaction.reservation}
        rfid={props.selectedTransaction === null ? null : props.selectedTransaction.id_tag}
        rfidAlias={props.selectedTransaction === null ? null : props.selectedTransaction.rfidAlias}
        unlocked={props.selectedTransaction === null ? true : props.selectedTransaction.status.unlocked}
        currencies={props.currencies}
        client={props.selectedTransaction === null ? null : props.selectedTransaction.client}
        notes={props.selectedTransaction === null ? null : props.selectedTransaction.notes}
      />
      <DialogTrend
        open={props.dialogTrendOpen}
        handleClose={props.toggleDialogTrend}
        transaction={props.selectedTransaction === null ? null : props.selectedTransaction}
        user={props.user}
      />
      <Grid container direction='column'>
        <LinearProgress color='primary' hidden={!props.processing} />
        <Grid container direction='column' className={classes.filtersContainer}>
          <Typography variant='h5'>
            <Translate id='transactions.finishedTitle' />
          </Typography>
          {(props.user.type === 'superuser' || (props.user.clientType === 'main' && props.user.clients.length > 0)) && (
            <Autocomplete
              options={props.clientsList}
              getOptionLabel={getOptionClientLabel(props.clientsList)}
              getOptionSelected={(o, t) => o.id === t.id}
              openOnFocus
              autoHighlight
              fullWidth
              value={props.filters.client}
              onChange={(event, newValue) => props.onChange({ target: { name: 'client', value: newValue } })}
              renderInput={(params) => <TextField {...params} label={props.translate('transactions.client')} />}
            />
          )}
          <Autocomplete
            options={props.chargeboxesList}
            getOptionLabel={getOptionLabel(props.chargeboxesList)}
            getOptionSelected={(o, t) => o.id === t.id}
            openOnFocus
            autoHighlight
            fullWidth
            value={props.filters.chargebox}
            onChange={(event, newValue) => props.onChange({ target: { name: 'chargebox', value: newValue } })}
            renderInput={(params) => <TextField {...params} label={props.translate('transactions.chargebox')} />}
          />
          <Grid container direction='row' wrap='wrap' alignItems='center' justifyContent='space-between' spacing={2}>
            <Grid container direction='row' alignItems='center' justifyContent='flex-start' spacing={2} item xs={12} md={6}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  variant='inline'
                  format='dd/MM/yyyy'
                  placeholder={props.translate('dateFormat')}
                  margin='normal'
                  label={<Translate id='transactions.from' />}
                  name='dateFrom'
                  value={props.filters.dateFrom}
                  maxDate={props.filters.dateTo === null ? new Date() : props.filters.dateTo}
                  onChange={(d) => props.onChange({ target: { name: 'dateFrom', value: d } })}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  autoOk
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  variant='inline'
                  format='dd/MM/yyyy'
                  placeholder={props.translate('dateFormat')}
                  margin='normal'
                  label={<Translate id='transactions.to' />}
                  name='dateTo'
                  value={props.filters.dateTo}
                  minDate={props.filters.dateFrom === null ? undefined : props.filters.dateFrom}
                  maxDate={new Date()}
                  onChange={(d) => props.onChange({ target: { name: 'dateTo', value: d } })}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  autoOk
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={['Paypal', 'Stripe', 'Nayax', 'RFID', 'API']}
                  getOptionSelected={(o, t) => o === t}
                  openOnFocus
                  autoHighlight
                  fullWidth
                  value={props.filters.method}
                  onChange={(event, newValue) => props.onChange({ target: { name: 'method', value: newValue } })}
                  renderInput={(params) => <TextField {...params} label={props.translate('transactions.paymentMethod')} />}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2} item xs={12} md={6}>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={props.submitFilters}
                disabled={
                  ((props.user.type === 'superuser' || (props.user.clientType === 'main' && props.user.clients.length > 0)) &&
                    !props.filters.client &&
                    !props.filters.chargebox) ||
                  props.processing
                }
              >
                <Translate id='transactions.search' />
              </Button>
              <Button variant='contained' className={classes.button} onClick={props.clearFilters}>
                <Translate id='transactions.clear' />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer>
          <Table aria-label='table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Translate id='transactions.chargebox' />
                </TableCell>
                <TableCell>
                  <Translate id='transactions.connector' />
                </TableCell>
                <TableCell>
                  <Translate id='transactions.transactionID' />
                </TableCell>
                <TableCell>
                  <Translate id='transactions.started' />
                </TableCell>
                <TableCell>
                  <Translate id='transactions.finished' />
                </TableCell>
                <TableCell>
                  <Translate id='transactions.consume' />
                </TableCell>
                <TableCell>
                  <Translate id='transactions.type.type' />
                </TableCell>
                <TableCell>
                  <Translate id='transactions.status.status' />
                </TableCell>
                <TableCell>
                  <IconButton onClick={props.refresh} disable={props.processing}>
                    <RefreshIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.map((row, id) => {
                let [, connector, transactionID] = row.id.split('__');
                return (
                  <TableRow key={id}>
                    <TableCell>{props.chargeboxes[row.chargebox]}</TableCell>
                    <TableCell>{connector}</TableCell>
                    <TableCell>{transactionID}</TableCell>
                    <TableCell>{getDate(row.date_started, props.user.locale)}</TableCell>
                    <TableCell>{getDate(row.date_finished, props.user.locale)}</TableCell>
                    <TableCell>{getConsume(row.meter_start, row.meter_stop)}</TableCell>
                    <TableCell>{getType(row.type, row.payment)}</TableCell>
                    <TableCell>{getStatus(row.status.unlocked)}</TableCell>
                    <TableCell>
                      <IconButton onClick={props.openCloseMenu(row)}>
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          rowsPerPageOptions={[10, 20, 50]}
          count={props.count}
          rowsPerPage={props.pagination.rowsPerPage}
          page={props.pagination.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
          labelRowsPerPage={props.translate('table.rowsPerPage')}
          nextIconButtonText={props.translate('table.next')}
          backIconButtonText={props.translate('table.previous')}
        />
        <Menu anchorEl={props.menuAnchorEl} open={props.menuAnchorEl !== null} onClose={props.openCloseMenu(null)}>
          {props.selectedTransaction !== null && (
            <MenuItem button onClick={props.toggleDialogPayment}>
              <ListItemIcon className={classes.listItemIcon}>
                <EuroIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit' noWrap>
                <Translate id='transactions.payment' />
              </Typography>
            </MenuItem>
          )}

          <MenuItem button onClick={props.toggleDialogTrend}>
            <ListItemIcon className={classes.listItemIcon}>
              <ShowChartIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit' noWrap>
              <Translate id='transactions.trend' />
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Paper>
  );
};
export default withLocalize(FinishedTransactions);
